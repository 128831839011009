import { Link } from "gatsby"
import { Link as ScrollLink } from "react-scroll"
import PropTypes from "prop-types"
import React from "react"
import logo from "../../static/images/logo-black.png"

const Nav = ({ siteTitle, noNav }) => (
  <header>
    <nav className="navbar navbar-light bg-light shadow fixed-top p-0">
      <div className="navbar-header">
        <div className="container">
          <ul className="info order-lg-1 order-2">
            <li>
              <a href="mailto:mhermosillo@mcproyectostelecom.com">
                <i className="icon-mail-1 contact" />{" "}
                info@mcproyectostelecom.com
              </a>
            </li>
            <li>
              <i className="icon-mobile contact" /> 55 2155 4518
            </li>
          </ul>
          <ul className="social order-lg-2 order-1">
            <li>
              <a href="https://www.facebook.com/MCProyectosTelecom/">
                <i className="icon-s-facebook" />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/mcproyectostelecom">
                <i className="icon-s-linkedin" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark shadow fixed-top fixed-top-2">
      <div className="container">
        <Link to="/" className="navbar-brand">
          <img src={logo} alt="logo" className="logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          {!noNav && (
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <ScrollLink
                  activeClass="active"
                  className="nav-link"
                  to="inicio"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={2500}
                >
                  INICIO
                </ScrollLink>
              </li>
              <li className="nav-item">
                <ScrollLink
                  activeClass="active"
                  className="nav-link"
                  to="nosotros"
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={2500}
                >
                  NOSOTROS
                </ScrollLink>
              </li>
              <li className="nav-item">
                <ScrollLink
                  activeClass="active"
                  className="nav-link"
                  to="servicios"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={2500}
                >
                  SERVICIOS
                </ScrollLink>
              </li>
              <li className="nav-item">
                <ScrollLink
                  activeClass="active"
                  className="nav-link"
                  to="process"
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={2500}
                >
                  NUESTRO TRABAJO
                </ScrollLink>
              </li>
              <li className="nav-item">
                <ScrollLink
                  activeClass="active"
                  className="nav-link"
                  to="contacto"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={2500}
                >
                  CONTACTO
                </ScrollLink>
              </li>
            </ul>
          )}
          {noNav && (
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  INICIO
                </Link>
              </li>
            </ul>
          )}
        </div>
      </div>
    </nav>
  </header>
)

Nav.propTypes = {
  siteTitle: PropTypes.string,
}

Nav.defaultProps = {
  siteTitle: ``,
}

export default Nav
