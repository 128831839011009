/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Nav from "./nav"
import logo from "../../static/images/logo-black.png"
import "../../static/styles/main.scss"
import { Link as ScrollLink } from "react-scroll"
// import iconTwitter from "../../static/images/icon-twitter.svg"
// import iconLinkedin from "../../static/images/icon-linkedin.svg"
// import iconGitHub from "../../static/images/icon-github.svg"
// import iconInstagram from "../../static/images/icon-instagram.svg"
import "bootstrap"

const Layout = ({ children, noNav }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Nav siteTitle={data.site.siteMetadata.title} noNav={noNav} />
        <main>{children}</main>
        <footer className="dark-bg">
          <div className="container inner">
            <div className="row">
              <div className="col-lg-3 col-md-6 inner">
                <a href="/">
                  <img className="logo img-intext" src={logo} alt="logo" />
                </a>
              </div>
              <div className="col-lg-3 col-md-6 inner">
                <h4>Estemos en contacto</h4>
                <p>Recibe noticias, promociones y novedades.</p>
                <form id="newsletter" className="form-inline newsletter">
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail"
                    placeholder="Ingresa tu correo"
                  />
                  <button type="submit" className="btn btn-submit">
                    Suscribete
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container inner clearfix">
              <p className="float-left">
                © 2019 MC PROYECTOS TELECOM. Todos los Derechos Reservados.
              </p>

              <ul className="footer-menu float-right">
                <li>
                  <ScrollLink
                    to="inicio"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={2500}
                  >
                    Inicio
                  </ScrollLink>
                </li>
                <li>
                  <ScrollLink
                    to="nosotros"
                    spy={true}
                    smooth={true}
                    offset={-80}
                    duration={2500}
                  >
                    Nosotros
                  </ScrollLink>
                </li>
                <li>
                  <ScrollLink
                    to="servicios"
                    spy={true}
                    smooth={true}
                    offset={-80}
                    duration={2500}
                  >
                    Servicios
                  </ScrollLink>
                </li>
                <li>
                  <ScrollLink
                    to="process"
                    spy={true}
                    smooth={true}
                    offset={-150}
                    duration={2500}
                  >
                    Nuestro trabajo
                  </ScrollLink>
                </li>
                <li>
                  <ScrollLink
                    to="contacto"
                    spy={true}
                    smooth={true}
                    offset={-80}
                    duration={2500}
                  >
                    Contacto
                  </ScrollLink>
                </li>
                <li>
                  <a href="http://www.mcproyectostelecom.com:2095/">
                    Webmail Empresa
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </footer>{" "}
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
